<template>
  <b-overlay :show="loading" rounded="sm" no-fade class="">
    <b-row class="mb-1">
      <b-col cols="12" md="3">
        <div class="d-flex align-items-center">
          <v-select v-model="selectedOption" :options="showOptions" :clearable="false" style="background-color: white;" class="w-100" @input="handleOptionChange" />
        </div>
      </b-col>

      <b-col cols="12" md="3" v-if="$Can('clockify_sync')">
        <div class="d-flex align-items-center">
          <b-button class="w-100" variant="outline-success" @click="getClientDataFromClockify">
            <span>{{ buttonLabel }}</span>
          </b-button>
        </div>
      </b-col>

      <b-col cols="12" md="6" v-if="$Can('client_add_btn')">
        <div class="d-flex align-items-center">
          <b-form-input v-model="searchText" class="d-inline-block mr-1" cle placeholder="Search..." />
          <b-button variant="primary" v-b-modal.modal-create-client>
            <span class="text-nowrap"> Create Client </span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="filteredClients.length > 0">
      <b-col cols="12" sm="6" md="4" lg="4" xl="3" v-for="client in filteredClients" :key="client.id">
        <div class="card" @click="redirectPreview(client.id)">
          <div class="banner" :style="'background-image: url(' + companiesURL + (client.logoLink ? client.logoLink : 'placeholder.jpg') + ');'"></div>
          <h2 :class="{ name: true, 'mt-2': true, strikethrough: isArchive === 1 }">
            {{ client.name }}
          </h2>
          <div class="title">
            <span :class="getTeamClass(client.team)">{{ client.team }} </span><br /><strong>{{ formatDate(client.startDate) }}</strong>
          </div>
          <div class="actions">
            <div class="task-info">
              <h2>
                <a
                  ><span>{{ client.totalProject }}</span
                  ><small>Project</small></a
                >
              </h2>
              <h2>
                <a
                  ><span>{{ client.completedProject }}</span
                  ><small>Done</small></a
                >
              </h2>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <div class="text-center text-muted" v-else><h2 class="text-muted mt-5">There are no clients</h2></div>

    <b-modal id="modal-create-client" @ok="handleFolderOk" @show="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Save" centered title="Create new client">
      <validation-observer ref="clientRules">
        <b-form>
          <b-form-group>
            <label for="clientName">Client Name</label>
            <validation-provider name="Client Name" #default="{ errors }" rules="required">
              <b-form-input id="clientName" type="text" v-model="newClientName" placeholder="Add new client" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import clientStoreModule from './clientStoreModule';
import vSelect from 'vue-select';
import VueContext from 'vue-context';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BBreadcrumb } from 'bootstrap-vue';
import { formatDate, getTeamClass } from '../helpers';
import { required, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    VueContext,
    BBreadcrumb,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const CLIENT_APP_STORE_MODULE_NAME = 'client';
    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      searchText: '',
      newClientName: null,
      companiesURL: store.state.app.companiesURL,
      loading: true,
      clients: [],
      isArchive: 0,
      selectedOption: 'Active',
      showOptions: ['Active', 'Archived'],
      buttonLabel: 'Get Data From Clockify',
    };
  },

  mounted() {
    this.getClients();
  },

  watch: {},

  methods: {
    formatDate,
    getTeamClass,

    getClientDataFromClockify() {
      this.buttonLabel = 'Fetching Data...';
      this.loading = true;
      store
        .dispatch('client/getClientDataFromClockify')
        .then((res) => {
          this.getClients();
          this.buttonLabel = 'Get Data From Clockify';
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    getClients() {
      this.loading = true;
      this.clients = [];
      store
        .dispatch('client/getClientAll', { isArchive: this.isArchive })
        .then((res) => {
          this.clients = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    handleOptionChange() {
      if (this.selectedOption === 'Active') {
        this.isArchive = 0;
        this.getClients();
      } else if (this.selectedOption === 'Archived') {
        this.isArchive = 1;
        this.getClients();
      }
    },

    redirectPreview(_id) {
      if (this.$Can('client_details_btn')) {
        this.$router.push({ name: 'client-preview', params: { id: _id } });
      }
    },

    handleFolderOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      this.$refs.clientRules.validate().then((success) => {
        if (success) {
          this.loading = true;
          store
            .dispatch('client/saveClient', { name: this.newClientName })
            .then((res) => {
              this.getClients();
              this.$bvModal.hide('modal-new-client');
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Folder has been created',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
            })
            .catch((error) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'An error occurred',
                  text: 'Please try again later or contact support.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    resetModal() {
      this.newClientName = null;
    },
  },

  computed: {
    filteredClients() {
      if (!this.searchText) {
        return this.clients;
      }
      const lowercaseSearchText = this.searchText.toLowerCase();
      return this.clients.filter((client) => client.name.toLowerCase().includes(lowercaseSearchText));
    },
  },
};
</script>

<style lang="scss" scoped>
.card:hover {
  // transform: scale(1.01);
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.card {
  background-color: #fff;
  // max-width: 360px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0rem;
  border: solid 1px #00000025;
}
.card .banner {
  // background-image: url('~@/assets/images/placeholder.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  // background-color: #00000010;
  margin: 10px 40px 0px 40px;

  //top - right - bottom - left

  // border-bottom: solid 1px #00000020;
}

.card h2.name {
  text-align: center;
  margin: 0;
  padding: 0rem 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: normal;
  font-size: 1.225rem;
}
.card .title {
  color: #a0a0a0;
  font-size: 0.85rem;
  text-align: center;
  padding: 0 2rem 1.2rem;
}
.card .actions {
  padding: 0 2rem 1.2rem;
  display: flex;
  flex-direction: column;
  order: 99;
}
.card .actions .task-info {
  padding: 0 0 1rem;
  display: flex;
}
.card .actions .task-info h2 {
  text-align: center;
  width: 50%;
  margin: 0;
  box-sizing: border-box;
}
.card .actions .task-info h2 a {
  text-decoration: none;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  transition: background-color 100ms ease-in-out;
}
.card .actions .task-info h2 a span {
  color: #73c0d2;
  font-weight: bold;
  transform-origin: bottom;
  transform: scaleY(1.3);
  transition: color 100ms ease-in-out;
}
.card .actions .task-info h2 a small {
  color: #afafaf;
  font-size: 0.85rem;
}

.card .desc {
  text-align: justify;
  padding: 0 2rem 2.5rem;
  order: 100;
}

.strikethrough {
  text-decoration: line-through;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~vue-context/dist/css/vue-context.css';
</style>
