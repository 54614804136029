import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getClientAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getClientAll', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listContacts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listContacts', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveClient(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveClient', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateClient(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateClient', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getClientById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getClientById/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getClientIdRetainers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getClientIdRetainers`, { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getRetainerList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getRetainerList`, queryParams)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    createRetainer(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createRetainer`, queryParams)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateRetainer(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateRetainer`, queryParams)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    addRetainerHours(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`addRetainerHours`, queryParams)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    cancelRetainer(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`cancelRetainer`, queryParams)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateSubtask(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateSubtask`, queryParams)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },



    



    

    getClientIdProjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getClientIdProjects`, { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    

    getClientIdReports(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('getClientIdReports', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getClientIdReports(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('getClientIdReports', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getClientIdReportProjects(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('getClientIdReportProjects', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('clientUserList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    clientContactList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('clientContactList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getClientNotes(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getClientNotes', { params: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveClientNote(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveClientNote', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateClientNote(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateClientNote', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    getRetainerNotes(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getRetainerNotes', { params: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveRetainerNote(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveRetainerNote', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateRetainerNote(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateRetainerNote', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },



    getClientDataFromClockify(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('getClientDataFromClockify', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
